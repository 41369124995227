import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding: 2rem 0;
  margin: 0 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 0 4rem;
    padding: 4rem 0;
    column-gap: 4rem;
  }
`;

export default function TwoColumn({ children }) {
  return <Holder>{children}</Holder>;
}

TwoColumn.propTypes = {
  children: PropTypes.array.isRequired,
};
