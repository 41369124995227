import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import Seo from "../components/atoms/Seo";
import Container from "../components/atoms/Container";
import TwoColumn from "../components/molecules/TwoColumn";
import ErrorThread from "../assets/images/errorpage-thread.inline.svg";

const Holder = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: ${props => props.theme.colours.navy};
  color: ${props => props.theme.colours.white};
  border-bottom: 1px solid ${props => props.theme.colours.white};
`;

const ThreadHolder = styled.div`
  display: none;
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 8rem;
    pointer-events: none;
  }
`;

export default function PageNotFound() {
  gsap.registerPlugin(DrawSVGPlugin);

  useEffect(() => {
    var tl2 = gsap.timeline({ defaults: { autoAlpha: 1 } });
    tl2.fromTo(
      ".path",
      { duration: 3, drawSVG: "0%" },
      { duration: 3, drawSVG: "0% 100%" },
    );
  });

  return (
    <Holder>
      <Seo title="404 : Not found" />
      <Container>
        <ThreadHolder>
          <ErrorThread />
        </ThreadHolder>
        <TwoColumn>
          <h1>Oh no!</h1>
          <div></div>
        </TwoColumn>
        <TwoColumn>
          <div></div>
          <div>
            <h4>Looks like we've taken a wrong turn.</h4>
            <Link to="/">
              <button className="button large">Home</button>
            </Link>
          </div>
        </TwoColumn>
      </Container>
    </Holder>
  );
}
